export function getClasses(data) {
   return new Promise(async (resolve, reject) => {
     try {
       const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/class/videos`, {
         method: "POST",
         body: JSON.stringify(data),
         headers: { "content-type": "application/json" },
	credentials: 'include'
       });
       if (response.ok) {
         const data = await response.json();
         resolve({ data });
       } else {
         const err = await response.text();
         reject(err);
       }
     } catch (err) {
       console.log(err);
     }
   });
 }
