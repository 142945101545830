export async function loginUser(loginInfo) {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(loginInfo);

      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user/login`, {
        method: "POST",
        body: JSON.stringify(loginInfo),
        headers: { "content-type": "application/json" },
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);

        resolve({ data });
      } else {
        const error = await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export async function checkAuth() {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user/check`, {
      method: "POST",
      body:JSON.stringify(),
      headers:{'content-type':'application/json'},
      credentials: 'include'
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || "Failed to authenticate");
    }

    const data = await response.json();
    return { data };
  } catch (error) {
    throw new Error(
      error.message || "Authentication failed due to an unexpected error"
    );
  }
}
