export function GetCourse() {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("Get Course API Called");
      
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/course`, {
        method: "GET",
        body:JSON.stringify(),
        headers: { "content-type": "application/json" },
        credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data)
        resolve({ data });
      }
      else {
         const err = await response.text();
         console.log(err)
         reject(err)
      }

    } catch (err) {
      console.log(err);
    }
  });
}
