export function AddContact(data) {
  return new Promise(async (resolve, reject) => {
    try {

      console.log(data)
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/contact`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "content-type": "application/json" },
	credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const err = await response.text();
        reject(err);
      }
    } catch (error) {
      reject(error);
    }
  });
}


export function NewsLetter(data) {
  return new Promise(async (resolve, reject) => {
    try {

      console.log(data)
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/contact/newsletter`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "content-type": "application/json" },
	credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const err = await response.text();
        reject(err);
      }
    } catch (error) {
      reject(error);
    }
  });
}
